import { useState } from 'react';
import { Button, Grid, Typography, Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartColumn } from '@fortawesome/free-solid-svg-icons';
import OntologyDataGrid from './OntologyDataGrid';
import PairwiseSimilarityTree from './PairwiseSimilarityTree';
import Spinner from './Spinner';
import makeStyles from '@mui/styles/makeStyles';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import useMediaQuery from '@mui/material/useMediaQuery';

import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';


const useStyles = makeStyles((theme) => ({
  grid: {
    backgroundColor: 'white',
    borderRight: '1px solid',
    borderLeft: '1px solid',
    borderRightColor: theme.palette.grey[300],
    borderLeftColor: theme.palette.grey[300],
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  calculate: {
    backgroundColor: 'white',
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  typo: {
    padding: theme.spacing(1),
  },
}));

function PairwiseSimilarityTab() {
  const [ids, setIds] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [btnEnabled, setBtnEnabled] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState(null);
  const [onto, setOnto] = useState(null);

  const [update, setUpdate] = useState(0);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const classes = useStyles();

  const onTextFieldChange = (event) => {
    setTextFieldValue(event.target.value);
    setBtnEnabled(selectedCount > 1 || (event.target.value.trim() && selectedCount > 0));
    setOnto(event.target.value.trim());
  };

  const handleButtonClick = () => {
    console.log('ids:' + ids);

    setUpdate(update + 1);
  };

  return (
    <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Grid item xs={10} md={12} className={classes.grid}>
        <Typography variant="h5" gutterBottom>
          Pairwise Similarity
        </Typography>
        <Typography variant="body2">
          The sandbox of the NFDI4Ing terminology service serves as a kind of showroom for new developments around the management, processing and handling of ontologies. The sandbox not only allows
          the tools to be tested on engineering-specific ontologies, but also on a range of other ontology collections. You can keep up to date with the latest developments in the Sandbox via our
        </Typography>
      </Grid>
      <Grid item xs={12} md={5.5} className={classes.grid}>
        <TextField
          id="outlined-full-width"
          label="TS internal ontology ID or external ontology URI"
          fullWidth
          multiline
          minRows={2}
          // InputLabelProps={{
          //   shrink: true,
          // }}
          variant="outlined"
          onChange={onTextFieldChange}
        />
        <Tooltip title="These are the ontologies available internally in Terminology Service">
          <Button variant="contained" type="primary" sx={{ margin: '1rem 0rem' }}>
            Internal Ontologies List
          </Button>
        </Tooltip>
        <OntologyDataGrid setSpinner={setSpinner} setSelectedCount={setSelectedCount} setBtnEnabled={setBtnEnabled} textFieldValue={textFieldValue} setIds={setIds} />
      </Grid>
      <Grid item xs={12} md={1} className={classes.calculate}>
        {!spinner ? (
          <Button variant="contained" type="primary" disabled={!btnEnabled} onClick={handleButtonClick}>
            {isSmallScreen ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowRightIcon />}
          </Button>
        ) : (
          <Spinner />
        )}
      </Grid>
      <Grid item xs={12} md={5.5} className={classes.grid}>
        <Button variant="contained" type="primary">
          Results Description
        </Button>
        <Typography variant="body1" className={classes.typo}>
          All selected ontologies are split into pairs. Every characteristic <code>(property, class, import, namespace, individual)</code> has a weight. For each characteristic a total number of
          elements and a number of shared elements are calculated. The result is sorted by percentage in descending order.
        </Typography>
        {update ? (
          <PairwiseSimilarityTree update={update} setSpinner={setSpinner} ids={ids} onto={onto} />
        ) : (
          <FontAwesomeIcon
            icon={faChartColumn}
            style={{
              height: '150px',
              width: '150px',
              opacity: '0.2',
              margin: 'auto',
            }}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default PairwiseSimilarityTab;
