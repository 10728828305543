import React from 'react'


class AboutApi extends React.Component{
    render(){
        return(
            <div className='doc-design'>
                <br/>
                <h2>API Documentation</h2>
                <p>Text here</p>
            </div>
        )
    }
}

export default AboutApi