import { useState, useEffect } from 'react';
import { Tab, Tabs, LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { a11yProps, TabPanel } from 'components/sandbox/theme/Theme';
import { DataAssessment } from './DataAssessment';
import { CommunityAssessment } from './CommunityAssessment';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(3),
    minHeight: '70vh',
    alignItems: 'center',
    justifyContent: 'center',
  },

  tab: {
    color: theme.palette.primary.light,
    fontWeight: 'bolder',
    backgroundColor: 'none',
    borderRadius: '5px 5px 0px 0px',
  },
  tabs: {
    transform: 'translate(0px, 1px)',
    '& .Mui-selected': {
      backgroundColor: 'white',
      borderRight: '1px solid',
      borderTop: '1px solid',
      borderLeft: '1px solid',
      borderRightColor: theme.palette.grey[400],
      borderLeftColor: theme.palette.grey[400],
      borderTopColor: theme.palette.grey[400],
    },
  },
  tabpanel: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    minHeight: '70vh',
    padding: theme.spacing(3),
    border: '1px solid',
    borderColor: theme.palette.grey[400],
    borderRadius: ' 0 0px 10px 10px',
  },
  progressBar: {
    width: '100%',
    '& .MuiLinearProgress-root': {
      height: '20px',
      color: theme.palette.primary.light,
    },
  },
}));

export const QualityAssessment = ({ repositoryData, githubOntologies }) => {
  const classes = useStyles();
  const [dataAssessmentRows, setDataAssessmentRows] = useState([]);
  const [communityAssessmentRows, setCommunityAssessmentRows] = useState([]);
  const [value, setValue] = useState(0);
  const [totalCount, setTotalCount] = useState({
    starsCount: 0,
    forkCount: 0,
    watchersCount: 0,
  });

  const handleCommunityPercentage = (params) => {
    let percentage = 0;
    if (params.row.forkCount) {
      percentage += (params.row.forkCount / totalCount.forkCount) * 33.33;
    }
    if (params.row.starsCount) {
      percentage += (params.row.starsCount / totalCount.starsCount) * 33.33;
    }
    if (params.row.watchersCount) {
      percentage += (params.row.watchersCount / totalCount.watchersCount) * 33.33;
    }

    return percentage;
  };

  const handleDataPercentage = (params) => {
    let percentage = 0;
    if (params.row.license) {
      percentage += 33.33;
    }
    if (params.row.readme) {
      percentage += 33.33;
    }
    if (params.row.release) {
      percentage += 33.33;
    }
    return percentage;
  };

  function RenderCommunityProgress(params) {
    return (
      <div className={classes.progressBar}>
        <LinearProgress variant="determinate" value={handleCommunityPercentage(params)} color="primary" />
      </div>
    );
  }

  function RenderDataProgress(params) {
    return (
      <div className={classes.progressBar}>
        <LinearProgress variant="determinate" value={handleDataPercentage(params)} color="primary" />
      </div>
    );
  }

  const dataAssessmentColumns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'ontologyName',
      headerName: 'Ontology',
      flex: 1,
      editable: true,
      align: 'left',
      fontWeight: 'bolder',
    },

    {
      field: 'license',
      headerName: 'License',
      width: 200,
      type: 'boolean',

      align: 'center',
    },
    {
      field: 'readme',
      headerName: 'ReadMe',
      type: 'boolean',
      width: 200,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'release',
      headerName: 'Release',
      type: 'boolean',
      width: 200,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'overallMeasure',
      headerName: 'Overall Measure',
      renderCell: RenderDataProgress,
      type: 'number',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
  ];

  const communityAssessmentColumns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'ontologyName',
      headerName: 'Ontology',
      flex: 1,
      editable: true,
      align: 'left',
      fontWeight: 'bolder',
    },
    {
      field: 'forkCount',
      headerName: 'Forks',
      width: 200,
      editable: true,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'starsCount',
      headerName: 'Stars',
      width: 100,
      editable: true,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'watchersCount',
      headerName: 'Watchers',
      width: 100,
      editable: true,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'overallMeasure',
      headerName: 'Overall Measure',
      renderCell: RenderCommunityProgress,
      type: 'number',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => {
        if (params.row.starsCount > totalCount.starsCount) {
          setTotalCount({ ...totalCount, starsCount: params.row.starsCount });
        }
        if (params.row.forkCount > totalCount.forkCount) {
          setTotalCount({ ...totalCount, forkCount: params.row.forkCount });
        }
        if (params.row.watchersCount > totalCount.watchersCount) {
          setTotalCount({
            ...totalCount,
            watchersCount: params.row.watchersCount,
          });
        }
      },
    },
  ];

  function handleDataAssessmentRowArray() {
    let rowArray = [];
    for (let i = 0; i < repositoryData.length; i = i + 3) {
      rowArray[i / 3] = {
        id: i / 3 + 1,
        ontologyName: githubOntologies[i / 3]?.label,
        license: repositoryData[i].data.license ? true : false,
        readme: repositoryData[i + 2]?.data?.files?.readme ? true : false,
        release: repositoryData[i + 1]?.data === [] ? false : true,
      };
    }
    return rowArray;
  }

  function handleCommunityAssessmentRowArray() {
    let rowArray = [];
    for (let i = 0; i < repositoryData.length; i = i + 3) {
      rowArray[i / 3] = {
        id: i / 3 + 1,
        ontologyName: githubOntologies[i / 3]?.label,
        forkCount: repositoryData[i].data.forks_count,
        starsCount: repositoryData[i].data.stargazers_count,
        watchersCount: repositoryData[i].data.subscribers_count,
      };
    }
    return rowArray;
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    let dataAssessmentRowArray = handleDataAssessmentRowArray();
    let communityAssessmentRowArray = handleCommunityAssessmentRowArray();
    setDataAssessmentRows(dataAssessmentRowArray);
    setCommunityAssessmentRows(communityAssessmentRowArray);
  }, []);

  return (
    <div className={classes.root}>
      <Tabs onChange={handleChange} value={value} className={classes.tabs} TabIndicatorProps={{ style: { display: 'none' } }}>
        <Tab label="Data Assessment" className={classes.tab} {...a11yProps(0)} />
        <Tab label="Community Assessment" className={classes.tab} {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0} className={classes.tabpanel}>
        <DataAssessment dataAssessmentRows={dataAssessmentRows} dataAssessmentColumns={dataAssessmentColumns} />
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.tabpanel}>
        <CommunityAssessment communityAssessmentRows={communityAssessmentRows} communityAssessmentColumns={communityAssessmentColumns} />
      </TabPanel>
    </div>
  );
};
