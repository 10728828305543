import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GetAllOntologies } from 'api/queryData';
import { useMemo } from 'react';
import MaterialReactTable from 'material-react-table';

const useStyles = makeStyles((theme) => ({
  cont: {
    minHeight: '90vh',
    borderBottomColor: theme.palette.grey[300],
  },
  grid: {
    padding: theme.spacing(3),
  },
}));

export const WebVOWL = () => {
  const columns = useMemo(
    () => [
      {
        accessorKey: 'ontologyId',
        header: 'Ontology Id',
        muiTableHeadCellProps: {
          align: 'left',
        },
      },

      {
        accessorKey: 'title',
        header: 'Title',
        muiTableHeadCellProps: {
          align: 'left',
        },
      },

      {
        accessorKey: 'fileLocation',
        header: 'File Location',
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
    ],
    []
  );

  const classes = useStyles();
  const { isLoading, data, isFetching } = GetAllOntologies();
  const allOntologies = data?.data['_embedded']['ontologies']?.map((item) => {
    return {
      ontologyId: item?.ontologyId,
      title: item?.config?.title,
      fileLocation: item?.config?.fileLocation,
    };
  });
  console.log(allOntologies);
  return (
    <>
      <Grid container className={classes.cont}>
        <Grid item md={1}></Grid>
        <Grid item md={9} className={classes.grid}>
          {isLoading && isFetching ? (
            <div>Loading ...</div>
          ) : (
            <MaterialReactTable
              columns={columns}
              data={allOntologies}
              enableColumnResizing={true}
              renderDetailPanel={({ row, table }) => (
                <div>
                  <iframe
                    title="WebVOWL"
                    style={{ position: 'center', height: '100%', width: '100%', border: 'none', minHeight: '70vh' }}
                    loading="lazy"
                    src={`https://service.tib.eu/webvowl/#iri=${row.original.fileLocation}`}
                  />
                </div>
              )}
              // muiTableProps={{
              //   sx: {
              //     borderCollapse: 'collapse',
              //   },
              // }}
              initialState={{ density: 'compact' }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
