import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  typo: {
    marginBottom: 20,
    lineHeight: 2,
  },
}));

export const MappingTypography = () => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h4" align="center" gutterBottom>
        Ontology Mapping
      </Typography>
      <Typography variant="body1" gutterBottom className={classes.typo}>
        <strong>Ontology Mapping</strong> is a process to articulate similarities among the concepts belonging to separate Ontologies. Given two ontologies, A and B, mapping one ontology with another
        means that for each concept in ontology A, we try to find a corresponding concept that has the same or similar semantics in ontology B, and vice versa. Ontology mapping is required to achieve
        knowledge sharing and semantic integration in an environment with different underlying ontologies.
        <br></br>
      </Typography>
    </div>
  );
};
