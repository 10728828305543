import React from 'react';
import './index.css';
import { ThemeProvider, StyledEngineProvider, CssBaseline } from '@mui/material';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { SandboxApp } from './SandboxApp';
import { BrowserRouter } from 'react-router-dom';
import { theme } from './components/sandbox/common/theme';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';

// import for the material-ui fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const instance = createInstance({
  urlBase: process.env.REACT_APP_TS_PUBLIC_URL as string,
  siteId: process.env.REACT_APP_TS_SITE_ID as any,
  trackerUrl: 'https://support.tib.eu/piwik/matomo.php',
  srcUrl: 'https://support.tib.eu/piwik/matomo.js',
  disabled: false,
  linkTracking: true,
  configurations: {
    disableCookies: true,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <MatomoProvider value={instance}>
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SandboxApp />
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </MatomoProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
