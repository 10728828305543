import {Octokit} from "octokit";

export const githubService = {
    getFileCommits,
    getSinceFileCommits,
    getUserFromUrl,
    getRepoFromUrl,
    getBranchFromUrl,
    getEncodedPath,
    getRawFileByCommit,
    getGitFileContent,
    checkGitFileUpdated,

    getRawFileByCommitURL,
};

const octokit= new Octokit({
    auth: process.env.REACT_APP_GITHUB_ACCESS_TOKEN
});


function getUserFromUrl(githubUrl) {
    return new URL(githubUrl).pathname.split('/')[1];
}

function getRepoFromUrl(githubUrl) {
    return new URL(githubUrl).pathname.split('/')[2];
}

function getBranchFromUrl(githubUrl) {
    return new URL(githubUrl).pathname.split('/')[5];
}

function getEncodedPath(githubUrl) {
    return new URL(githubUrl).pathname
        .split('/')
        .splice(4)
        .join('/');
}

async function getFileCommits(githubUrl) {
    const user = getUserFromUrl(githubUrl);
    const repo = getRepoFromUrl(githubUrl);
    const encodedPath = getEncodedPath(githubUrl);

    const response = await fetch(`${process.env.REACT_APP_GITHUB_API_BASE_URL}/repos/${user}/${repo}/commits?path=${encodedPath}`);
    return await response.json();
}

async function getSinceFileCommits(githubUrl, sinceDate) {
    const user = getUserFromUrl(githubUrl);
    const repo = getRepoFromUrl(githubUrl);
    const encodedPath = getEncodedPath(githubUrl);

    const response = await octokit.request('GET /repos/{owner}/{repo}/commits', {
        owner: user,
        repo: repo,
        path: encodedPath,
        since: sinceDate
    });

    return response.data;
}

async function getRawFileByCommit(githubUrl, commitId) {
    const user = getUserFromUrl(githubUrl);
    const repo = getRepoFromUrl(githubUrl);
    const encodedPath = getEncodedPath(githubUrl);

    const response = await octokit.request('GET /repos/{owner}/{repo}/contents/{path}', {
        owner: user,
        repo: repo,
        path: encodedPath,
        ref: commitId
    })

    //Why the raw url is used described here:
    //https://docs.github.com/en/rest/repos/contents?apiVersion=2022-11-28#get-repository-content
    //tl;dr: when file is bigger than 1 Mb - the content with the value is blank, and raw url should be used

    const responseRawFile = await fetch(response.data.download_url);

    return await responseRawFile.text();
}

function getRawFileByCommitURL(githubUrl, commitId) {
    const user = getUserFromUrl(githubUrl);
    const repo = getRepoFromUrl(githubUrl);
    const encodedPath = getEncodedPath(githubUrl);

    return `https://raw.githubusercontent.com/${user}/${repo}/${commitId}/${encodedPath}`;
}

async function getGitFileContent(githubUrl) {
    const user = getUserFromUrl(githubUrl);
    const repo = getRepoFromUrl(githubUrl);
    const encodedPath = getEncodedPath(githubUrl);
    const branch = getBranchFromUrl(githubUrl);

    const response = await fetch(`${process.env.REACT_APP_GITHUB_API_BASE_URL}/repos/${user}/${repo}/contents/${encodedPath}?ref=${branch}`);
    const data = await response.json();
    const content = await fetch(data['git_url']);

    return atob((await content.json()).content);
}

async function checkGitFileUpdated(githubApiUrl, lastFetchedFileSha) {
    return {
        status: 'latest',
        commitsBehind: 0,
        branch: 'main'
    }
}

