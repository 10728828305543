import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';
import { alpha, styled } from '@mui/material/styles';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { PairwiseSimilarityBar } from './PairwiseSimilarityBar';
import Collapse from '@mui/material/Collapse';
import { useSpring, animated } from '@react-spring/web';
import { Box, Button } from '@mui/material';

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon className="close" fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(20px,0,0)',
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const StyledTreeItem = styled((props) => <TreeItem {...props} TransitionComponent={TransitionComponent} />)(({ theme }) => ({
  [`& .${treeItemClasses.label}`]: {
    fontSize: '14px',
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

function PairwiseSimilarityTree({ update, setSpinner, ids, onto }) {
  const [pairwiseSimilarities, setPairwiseSimilarities] = useState([]);

  const fetchData = async () => {
    if (ids.length === 0) {
      return;
    }
    setSpinner(true);
    const idsString = ids.join();
    let url;
    console.log(pairwiseSimilarities);
    if (onto) {
      if (onto.toLowerCase().startsWith('http')) {
        url = `${process.env.REACT_APP_MAPPING_BACKEND_URL}/api/ontology/similarity/pairwise/external/list?url=${onto}&ids=${idsString}&size=10000000`;
      } else {
        url = `${process.env.REACT_APP_MAPPING_BACKEND_URL}/api/ontology/similarity/pairwise/internal?id=${onto}&ids=${idsString}&size=10000000`;
      }
    } else {
      url = `${process.env.REACT_APP_MAPPING_BACKEND_URL}/api/ontology/similarity/pairwise/internal/list?ids=${idsString}&size=10000000`;
    }
    console.log('url:' + url);
    try {
      const response = await fetch(url, { method: 'GET' });
      const json = await response.json();
      setPairwiseSimilarities(json['content']);
    } catch (error) {
      console.log('error', error);
    }
    setSpinner(false);
  };

  useEffect(() => {
    fetchData();
  }, [update]);

  const renderPair = (j) => (
    <Box sx={{ marginBottom: '12px', display: 'flex', flexDirection: 'column' }}>
      <Button variant="outlined" sx={{ marginBottom: '12px' }}>
        {pairwiseSimilarities[j].titles.first + ' - ' + pairwiseSimilarities[j].titles.second}
      </Button>
      <StyledTreeItem nodeId={j} label={pairwiseSimilarities[j].pair.first + '-' + pairwiseSimilarities[j].pair.second + ', ' + parseFloat(pairwiseSimilarities[j].percentage).toFixed(2) + '%'}>
        {renderCharacteristics(j, 'property')}
        {renderCharacteristics(j, 'class')}
        {renderCharacteristics(j, 'import')}
        {renderCharacteristics(j, 'namespace')}
        {renderCharacteristics(j, 'individual')}
      </StyledTreeItem>
      <PairwiseSimilarityBar pairwiseSimilarity={pairwiseSimilarities[j]} />
    </Box>
  );

  const renderCharacteristics = (j, name) => {
    let percentValue = pairwiseSimilarities[j]['characteristics'][name]['percent'];

    return (
      <StyledTreeItem nodeId={name + j} label={name + ', ' + parseFloat(percentValue).toFixed(2) + '%'}>
        {pairwiseSimilarities[j]['characteristics'][name]['list'].map((item, index) => (
          <StyledTreeItem
            key={name}
            nodeId={pairwiseSimilarities[j]['characteristics'][name]['list'][index]['first'] + index}
            label={pairwiseSimilarities[j]['characteristics'][name]['list'][index]['first']}
          />
        ))}
      </StyledTreeItem>
    );
  };

  const renderTree = () => {
    return (
      <TreeView
        aria-label="customized"
        defaultExpanded={['1']}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        defaultEndIcon={<CloseSquare />}
        sx={{ flexGrow: 1, overflowY: 'auto' }}
      >
        {pairwiseSimilarities.map((item, index) => renderPair(index))}
      </TreeView>
    );
  };

  return renderTree();
}

export default PairwiseSimilarityTree;
