import MaterialReactTable from 'material-react-table';
import {React, useMemo, useRef, useState} from 'react';
import {Button, Dialog, DialogTitle, Grid, Link, MenuItem, Typography} from "@mui/material";
import { faGithub} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CodeMirror from '@uiw/react-codemirror';
import { json } from '@codemirror/lang-json';
import Report from "../Report";
import Box from "@mui/material/Box";
import {ToggleCloseButton} from "../ToggleCloseButton";
import IconButton from "@mui/material/IconButton";
import ButtonCard from "../ButtonCard";
import PropTypes from "prop-types";
import EditDialog from "../EditDialog";
import ConfirmationDialog from "../ConfirmationDialog";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import {Link as RouterLink} from "react-router-dom";
import ChangesTimeline from "./ChangesTimeline";
import {CriteriaConto} from "./CriteriaConto";
import ReportConto from "./ReportConto";
import DialogHeader from "../DialogHeader";

export const OndetTable = ({ontologies,
                              apiUrl,
                              makeDiff,
                              onUpdated,
                              onSaved,
                              onDeleted}) => {

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [copen, setCopen] = useState(false);
    const [sopen, setSopen] = useState(false);
    const [ropen, setRopen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [updatedOntologies, setUpdatedOntologies] = useState([]);
    const [isDeleting, setIsDeleting] = useState(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingRow, setEditingRow] = useState(null);
    let newCommits = useRef(null);

    const handleClose = () => {
        setOpen(false);
    };

    const openRowFunctions = (row) => {
        setSelectedRow(row);
        setOpen(true);
    }

    const handleDelete = (row) => {
        setSelectedRow(row);
        setConfirmationDialogOpen(true);
    }

    const confirmDelete = async () => {
        setConfirmationDialogOpen(false);
        setIsDeleting(true);
        await fetch(apiUrl + `${selectedRow.original.id}`, {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json'},
        });
        setIsDeleting(false);
        onDeleted();
    }

    const cancelDelete = () => {
        setConfirmationDialogOpen(false);
    }

    const handleEdit = async (row) => {
        setIsEditing(true);
        setEditingRow(row.original);
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: 'open',
                header: 'Open row functions',
                Cell: ({ cell }) => (
                    <IconButton onClick={() => openRowFunctions(cell.row)}>
                        <ExpandCircleDownOutlinedIcon />
                    </IconButton>
                ),
            },
            {
                accessorFn: (row) => {
                    const fullPath = row.name;
                    const fileName = fullPath.split('/').pop();
                    return fileName;
                },
                id: 'name',
                header: 'Name for ontologies',

            },
            {
                accessorKey: 'type',
                header: 'Git Type',
                filterVariant: 'select',
                Cell: ({ cell }) => (
                    <>
                        <FontAwesomeIcon icon={faGithub} />
                        {' '}Github
                    </>
                ),
            },
        ], [],);



    return (
        <>
            {selectedRow && (

                <Dialog style={{ marginTop: '20px' }} open={open} onClose={handleClose}
                        PaperProps={{style: {
                                minHeight: '35%',
                                maxHeight: '35%',
                                minWidth: '80%',
                                maxWidth: '80%',
                                position: 'relative',
                            }}}>

                    <DialogTitle>Ontology functions</DialogTitle>

                    <Grid container spacing={5}>
                        <ButtonCard
                            title="Query full ontology history"
                            text="With this function, you can visualise all changes to your Ontology,
                            beyond all Git commits. This functionality will give you a comprehensive
                            overview of the development history of your ontology"
                            helper={<Link component={RouterLink} to={'?howtouse=true&sectionId=timeline'}>Instructions for use</Link>}
                            onClick={() => {
                                setCopen(true);
                            }}
                        />
                        <ButtonCard
                            title="Axiom specific changes"
                            text="With this function, you can visualise all changes to one axiom from your Ontology,
                                    in a your chosen Git commit period. This functionality will give you a comprehensive
                                    overview of the development history of your ontology"
                            helper={<Link component={RouterLink} to={'?howtouse=true&sectionId=axiom'}>Instructions for use</Link>}
                            onClick={() => {
                                setSopen(true);
                            }}
                        />
                        <ButtonCard
                            title="Create change report"
                            text="With this function, you can create reports, that includes aggregated information
                                about your Ontology (number of added, removed axioms, most frequent axiom) in a given
                                period of time on top of history development information of your Ontology.
                                "
                            helper={<Link component={RouterLink} to={'?howtouse=true&sectionId=rawUri'}>Instructions for use</Link>}
                            onClick={() => {
                                setRopen(true);
                            }}
                        />
                    </Grid>
                    <ToggleCloseButton onClose={handleClose} />
                </Dialog>
            )}
            {copen && (
                <Dialog
                    style={{ marginTop: '20px' }}
                    open={copen}
                    onClose={() => {
                        setCopen(false);
                    }}
                    PaperProps={{
                        style: {
                            minHeight: '95%',
                            maxHeight: '95%',
                            minWidth: '90%',
                            maxWidth: '90%',
                            position: 'relative',
                        },
                    }}
                >
                    <DialogHeader title1='Timeline of the whole ontology' title2='Semantic Differences' />
                    <ChangesTimeline id={selectedRow?.original.name} />
                    <ToggleCloseButton onClose={() => {
                        setCopen(false);
                    }} />
                </Dialog>
            )}

            {sopen && (
                <Dialog
                    style={{ marginTop: '20px' }}
                    open={sopen}
                    onClose={() => {
                        setSopen(false);
                    }}
                    PaperProps={{
                        style: {
                            minHeight: '95%',
                            maxHeight: '95%',
                            minWidth: '70%',
                            maxWidth: '70%',
                            position: 'relative',
                        },
                    }}
                >
                    <DialogTitle>Axiom specific changes</DialogTitle>
                    <CriteriaConto id={selectedRow?.original.name} />
                    <ToggleCloseButton onClose={() => {
                        setSopen(false);
                    }} />
                </Dialog>
            )}

            {ropen && (
                <Dialog
                    style={{ marginTop: '20px' }}
                    open={ropen}
                    onClose={() => {
                        setRopen(false);
                    }}
                    PaperProps={{
                        style: {
                            minHeight: '95%',
                            maxHeight: '95%',
                            minWidth: '90%',
                            maxWidth: '90%',
                            position: 'relative',
                        },
                    }}
                >
                    <DialogTitle>Create change report</DialogTitle>
                    <ReportConto id={selectedRow?.original.name} />
                    <div>
                        <ToggleCloseButton onClose={() => {
                            setRopen(false);
                        }} />
                    </div>
                </Dialog>
            )}
            <MaterialReactTable
                enableRowActions
                positionActionsColumn="last"
                renderRowActionMenuItems={({ row }) => [
                    <MenuItem key="edit" onClick={() => handleEdit(row)}>
                        Edit
                    </MenuItem>,
                    <MenuItem key="edit" onClick={() => handleDelete(row)}>
                        Delete
                    </MenuItem>,
                ]}

                columns={columns}
                data={ontologies}
                enableFacetedValues

            />
            {isEditing && <EditDialog
                open={isEditing}
                onClose={() => setIsEditing(false)}
                onSave={() => onSaved()}
                data={editingRow}
            />}

            <ConfirmationDialog
                open={confirmationDialogOpen}
                onCancel={cancelDelete}
                onConfirm={confirmDelete}
                title="Delete ontology"
                message="Are you sure you want to delete this ontology?"
            />
        </>
    )
}

OndetTable.propTypes = {
    ontologies: PropTypes.array.isRequired
}