import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid } from '@mui/x-data-grid';

const useStyles = makeStyles((theme) => ({
  columnHeader: {
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
}));

export const CommunityAssessment = ({ communityAssessmentRows, communityAssessmentColumns }) => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h4" gutterBottom component="div">
        Community Assessment
      </Typography>
      <Typography variant="body1" gutterBottom>
        An overview of the Quality of Github ontologies maintained in Terminology Service
      </Typography>
      <DataGrid
        rowClick="show"
        classes={{
          columnHeader: classes.columnHeader,
          row: classes.row,
        }}
        rows={communityAssessmentRows}
        columns={communityAssessmentColumns}
        pageSize={30}
        autoHeight={true}
        rowsPerPageOptions={[30]}
        disableSelectionOnClick
        sx={{
          '& .MuiDataGrid-iconSeparator': {
            display: 'none',
          },
          '& .MuiSvgIcon-root': {
            color: 'white',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '16px',
            fontWeight: '500',
          },
        }}
      />
    </div>
  );
};
