import { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
  {
    field: 'ontologyId',
    headerName: 'Ontology ID',
    width: 100,
  },
  {
    field: 'title',
    headerName: 'Ontology Title',
    flex: 1,
  },
];

function OntologyDataGrid({ setSpinner, setSelectedCount, setBtnEnabled, textFieldValue, setIds }) {
  const [ontologies, setOntologies] = useState([]);

  const url = `${process.env.REACT_APP_MAPPING_BACKEND_URL}/api/ontology/list?size=10000`;

  const fetchData = async () => {
    setSpinner(true);
    try {
      const response = await fetch(url, { method: 'GET' });
      const json = await response.json();
      setOntologies(json);
    } catch (error) {
      console.log('error', error);
    }
    setSpinner(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onOntologySelect = (newSelectionModel) => {
    setSelectedCount(newSelectionModel.length);
    setBtnEnabled(newSelectionModel.length > 1 || (textFieldValue && textFieldValue.trim() && newSelectionModel.length > 0));
    setIds(ontologies.filter((ont) => newSelectionModel.includes(ont.id)).map((ont) => ont.ontologyId));
  };

  return (
    <DataGrid
      rows={ontologies}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 15,
          },
        },
      }}
      pageSizeOptions={[15]}
      checkboxSelection
      disableSelectionOnClick
      onRowSelectionModelChange={onOntologySelect}
      rowHeight={40}
      autoHeight
    />
  );
}

export default OntologyDataGrid;
