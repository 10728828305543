import { useState, useEffect } from 'react';
import Footer from './components/common/Footer/Footer';
import Header from './components/common/Header/Header';
import { MatomoWrapper } from './components/Matomo/MatomoWrapper';
import CookieBanner from './components/common/CookieBanner/CookieBanner';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import AppHelpers from './AppHelpers';
import { isLogin } from './components/User/Login/TS/Auth';
import AppRouter from './Router';
import { LoginLoadingAnimation } from './components/User/Login/LoginLoading';
import './components/layout/common.css';
import './components/layout/mediaQueries.css';
import './components/layout/custom.css';

// Sandbox related imports
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { SandboxFooter } from 'components/sandbox/common/SandboxFooter';
import { useLocation } from 'react-router';

const queryClientConfig = {
  defaultOptions: {
    queries: {
      retry: 2,
      refetchOnMount: 'false',
      refetchOnWindowFocus: 'false',
      refetchOnReconnect: 'always',
      cacheTime: 1000 * 60 * 60 * 24, //24 hours
      // refetchInterval: 1000 * 60 * 10, //30 seconds
      // refetchIntervalInBackground: false,
      // suspense: false,
      staleTime: 1000 * 60 * 60 * 24,
    },
  },
};

const queryClient = new QueryClient(queryClientConfig);

export const SandboxApp = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AppHelpers.setSiteTitleAndFavIcon();
    AppHelpers.checkBackendStatus();

    if (process.env.REACT_APP_AUTH_FEATURE !== 'true') {
      // set login status
      isLogin().then((resp) => {
        localStorage.setItem('isLoginInTs', resp);
      });
    }

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const location = useLocation();

  return (
    <div className="App">
      <LoginLoadingAnimation />
      <QueryClientProvider client={queryClient}>
        <MatomoWrapper>
          <div className={location.pathname.includes('/ts/sandbox') ? '' : 'container-fluid'} style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header />
            <div style={{ flex: 1 }}>
              <div className={location.pathname.includes('/ts/sandbox') ? '' : 'application-content'} id="application_content">
                {loading && <Skeleton count={2} wrapper={AppHelpers.InlineWrapperWithMargin} inline width={600} height={200} marginLeft={20} baseColor={'#f4f2f2'} />}
                {!loading && (
                  <>
                    <span id="backend-is-down-message-span"></span>
                    <CookieBanner />
                    <AppRouter />
                  </>
                )}
              </div>
            </div>
            <SandboxFooter />
          </div>
        </MatomoWrapper>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </div>
  );
};
