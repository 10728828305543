import { Swiper, SwiperSlide } from 'swiper/react';
import { makeStyles } from '@mui/styles';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { EffectCoverflow, Pagination, Navigation } from 'swiper';
import sandbox_white_logo from '../..//../assets/img/sandbox_logos/sandbox_white_logo.svg';
import diff_card_logo from '../..//../assets/img/sandbox_logos/diff_card_logo.svg';
import sandbox_orange_logo from '../..//../assets/img/sandbox_logos/sandbox_orange_logo.svg';
import fairds_logo_3 from '../../../assets/img/sandbox_logos/Untitled_fairds.svg';
import coypu_logo_3 from '../../../assets/img/sandbox_logos/Untitled_coypu.svg';
import nfdi4ing_logo_3 from '../../../assets/img/sandbox_logos/Untitled_nfdi4ing.svg';
import mapping_card_logo from '../../../assets/img/sandbox_logos/mapping_card_logo.svg';
import webprotege_card_logo from '../../../assets/img/sandbox_logos/webproteg.png';
import annotation_card_logo from '../../../assets/img/sandbox_logos/annotation-card-logo.svg';
import reverse_dictionary_card_logo from '../../../assets/img/sandbox_logos/reverse-dictionary-card-logo.svg';
import git_card_logo from '../../../assets/img/sandbox_logos/git.png';
import { Grid, Typography, useTheme } from '@mui/material';

// Material-UI styles
const useStyles = makeStyles((theme) => ({
  swiperContainer: {
    height: '100%',
    width: '70vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
  },
  swiperSlide: {
    width: '40rem',
    height: '30rem',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    borderRadius: '2rem',
  },
  swiperSlideImage: {
    width: '40rem',
    height: '30rem',
    borderRadius: '2rem',
    objectFit: 'cover',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
  swiperNavigationButton: {
    color: theme.palette.primary.main,
    zIndex: 1,
  },
  swiperButtonNext: {
    right: 30,
  },
  swiperButtonPrev: {
    left: 30,
  },
  cardContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 30,
    textAlign: 'center',
  },
  welcomeLogo: {
    height: '55%',
    marginRight: '3rem',
  },
  slideFooterLogo: {
    width: '2rem',
    height: '2rem',
    position: 'absolute',
    bottom: '0.5rem',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 200,
  },
  mappingLogo: {
    height: '10rem',
    padding: '1rem',
  },
  diffLogo: {
    height: '60%',
    padding: '1rem',
  },
  webprotegeLogo: {
    height: '10rem',
    width: '15rem',
  },
  gitLogo: {
    height: '40%',
  },
  annotationLogo: {
    height: '20rem',
    width: '30rem',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 30,
    alignItems: 'center',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.common.white,
    padding: '1rem',
  },
  fairdsLogo: {
    height: '4rem',
    alignSelf: 'flex-start',
  },
  coypuLogo: {
    height: '4rem',
  },
  nfdi4ingLogo: {
    transform: 'translateY(-6rem)',
    alignSelf: 'flex-end',
  },
  headingTypo: { fontSize: theme.typography.h4.fontSize, fontWeight: 400, color: theme.palette.common.white },
  bodyTypo: { fontSize: theme.typography.body1.fontSize, fontWeight: 400, color: theme.palette.common.white, textAlign: 'center', justifySelf: 'flex-end' },
}));

export const SandboxHome = () => {
  const classes = useStyles();
  const theme = useTheme();

  const commonStyles = {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 30,
    padding: '2rem',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  };

  const renderWelcomeSlide = () => {
    return (
      <div className={classes.cardContainer}>
        <div
          style={{
            ...commonStyles,
          }}
        >
          <img src={sandbox_white_logo} alt="sandbox logo" className={classes.welcomeLogo} />
          <Typography className={classes.headingTypo}>Welcome to the Sandbox!</Typography>
          <Typography className={classes.bodyTypo}>
            You will find here our recent developments on the application and work on ontologies. Feel free to try everything. We are happy about any feedback that you share with us{' '}
          </Typography>
        </div>
        <img src={sandbox_white_logo} alt="slide footer logo" className={classes.slideFooterLogo} />
      </div>
    );
  };

  const renderProjectsInvolvedSlide = () => {
    return (
      <div className={classes.cardContainer}>
        <div
          style={{
            ...commonStyles,
          }}
        >
          <Typography className={classes.headingTypo}>Who is involved?</Typography>
          <Typography className={classes.bodyTypo}>
            The tools provided in the Sandbox are implemented through several funded projects. Currently, we are working on the offering in three projects. More projects are about contribute as well
          </Typography>
        </div>
        <div className={classes.logoContainer}>
          <img src={fairds_logo_3} alt="fairds logo" className={classes.fairdsLogo} />
          <img src={coypu_logo_3} alt="coypu logo" className={classes.coypuLogo} />
          <img src={nfdi4ing_logo_3} alt="nfdi4ing logo" className={classes.nfdi4ingLogo} />
        </div>
      </div>
    );
  };

  const renderWebProtegeSlide = () => {
    return (
      <div className={classes.cardContainer}>
        <div
          style={{
            ...commonStyles,
          }}
        >
          <Typography className={classes.headingTypo}>Shared Editing and Versioning?</Typography>
          <Typography className={classes.bodyTypo}>
            WebProtégé, from Stanford University, is the tool for collaborative work on ontologies. Until now, however, it lacked direct integration with Git. We have extended WebProtégé with this
            missing component
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            backgroundColor: theme.palette.common.white,
            borderRadius: 30,
            alignItems: 'center',
            justifyContent: 'space-around',
            overflow: 'hidden',
            height: '100%',
            width: '100%',
            padding: '1rem',
          }}
        >
          <img src={webprotege_card_logo} alt="webprotege logo" className={classes.webprotegeLogo} />
          <img src={git_card_logo} alt="git logo" className={classes.gitLogo} />
        </div>
        <img src={sandbox_orange_logo} alt="slide footer logo" className={classes.slideFooterLogo} />
      </div>
    );
  };

  const renderMappingSlide = () => {
    return (
      <div className={classes.cardContainer}>
        <div
          style={{
            ...commonStyles,
            backgroundColor: theme.palette.common.white,
            padding: 0,
          }}
        >
          <img src={mapping_card_logo} alt="mapping logo" className={classes.mappingLogo} />
        </div>
        <div
          style={{
            ...commonStyles,
          }}
        >
          <Typography className={classes.headingTypo}>What links Ontologies?</Typography>
          <Typography className={classes.bodyTypo}>
            ... of the Terminology Service on a semantic level? More precisely, which links are they and do they possibly contradict each other? Get an overview or discover new connections between
            ontologies that you can use for your development.
          </Typography>
        </div>
        <img src={sandbox_white_logo} alt="slide footer logo" className={classes.slideFooterLogo} />
      </div>
    );
  };

  const renderSemanticDiffSlide = () => {
    return (
      <div className={classes.cardContainer}>
        <div
          style={{
            ...commonStyles,
            paddingBottom: 0,
          }}
        >
          <Typography className={classes.headingTypo}>What has changed …</Typography>
          <Typography className={classes.bodyTypo}>
            in the last versions of an ontology? Which concepts or relations were newly introduced, changed, deleted or moved and when? Which other axioms were introduced or changed and when? Which
            other axioms were introduced or changed and when?
          </Typography>
        </div>
        <img src={diff_card_logo} alt="diff logo" className={classes.diffLogo} style={{ alignSelf: 'flex-start', marginLeft: '2rem', zIndex: 100 }} />
        <div
          style={{
            borderRadius: 30,
            height: '100%',
            width: '100%',
            backgroundColor: theme.palette.common.white,
            transform: 'translateY(-86%)',
            color: theme.palette.common.white,
            padding: '102px',
            zIndex: 50,
          }}
        ></div>
        <img src={sandbox_orange_logo} alt="slide footer logo" className={classes.slideFooterLogo} />
      </div>
    );
  };

  const renderAnnotationSlide = () => {
    return (
      <div className={classes.cardContainer}>
        <div
          style={{
            ...commonStyles,
          }}
        >
          <Typography className={classes.headingTypo}>Text Annotation ...</Typography>
          <br />
          <Typography className={classes.bodyTypo}>... would you like to index your text with the help of controlled vocabulary? Our text annotator will help you do just that</Typography>
        </div>
        <div
          style={{
            display: 'flex',
            backgroundColor: theme.palette.common.white,
            borderRadius: 30,
            alignItems: 'center',
            justifyContent: 'space-around',
            overflow: 'hidden',
            height: '100%',
            width: '100%',
            padding: '1rem',
          }}
        >
          <img src={annotation_card_logo} alt="Annotation logo" className={classes.annotationLogo} />
        </div>
      </div>
    );
  };

  const renderSemanticSearchSlide = () => {
    return (
      <div className={classes.cardContainer}>
        <div
          style={{
            ...commonStyles,
            backgroundColor: theme.palette.common.white,
            padding: 0,
          }}
        >
          <img src={reverse_dictionary_card_logo} alt="mapping logo" className={classes.mappingLogo} />
        </div>
        <div
          style={{
            ...commonStyles,
          }}
        >
          <Typography className={classes.headingTypo}>What is the right term for . . .</Typography>
          <Typography className={classes.bodyTypo}>
            do you have the right term on the tip of your tongue but can only paraphrase it? Or are you looking for terms other than a given one? The Semantic Search will help you. Try it
          </Typography>
        </div>
        <img src={sandbox_white_logo} alt="slide footer logo" className={classes.slideFooterLogo} />
      </div>
    );
  };

  return (
    <Grid container>
      <Grid item md={12}>
        <Swiper
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={2}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 12,
          }}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
            clickable: true,
          }}
          modules={[EffectCoverflow, Pagination, Navigation]}
          className={classes.swiperContainer}
        >
          <SwiperSlide className={classes.swiperSlide}>{() => renderWelcomeSlide()}</SwiperSlide>
          <SwiperSlide className={classes.swiperSlide}>{() => renderProjectsInvolvedSlide()}</SwiperSlide>
          <SwiperSlide className={classes.swiperSlide}>{() => renderWebProtegeSlide()}</SwiperSlide>
          <SwiperSlide className={classes.swiperSlide}>{() => renderMappingSlide()}</SwiperSlide>
          <SwiperSlide className={classes.swiperSlide}>{() => renderSemanticDiffSlide()}</SwiperSlide>
          <SwiperSlide className={classes.swiperSlide}>{() => renderAnnotationSlide()}</SwiperSlide>
          {/* <SwiperSlide className={classes.swiperSlide}>{() => renderSemanticSearchSlide()}</SwiperSlide> */}

          <div className={`swiper-button-prev ${classes.swiperNavigationButton} ${classes.swiperButtonPrev}`}></div>
          <div className={`swiper-button-next ${classes.swiperNavigationButton} ${classes.swiperButtonNext}`}></div>
        </Swiper>
      </Grid>
    </Grid>
  );
};
