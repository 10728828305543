import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    height: 500,
    width: '40%',
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 300,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minHeight: 700,
    overflowY: 'auto',
  },
  columnHeader: {
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  cell: {
    fontWeight: 'bold',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
}));

export const ModalTable = React.forwardRef(({ rowParameters, firstTitle, secondTitle }) => {
  const classes = useStyles();
  const [modalStyle, _] = useState(getModalStyle);
  console.log('firsttitle' + firstTitle);
  console.log('rowparameters', rowParameters);

  const columns = [
    // { field: 'id', headerName: 'ID', width: 120 },
    {
      field: 'first',
      headerName: `${firstTitle}`,
      flex: 1,
      editable: true,
    },
    {
      field: 'second',
      headerName: `${secondTitle}`,
      flex: 1,
      editable: true,
    },
  ];

  const rows = rowParameters?.map((item, index) => {
    return {
      id: index,
      first: item?.second,
      firstUrl: item?.first,
      second: item?.second,
      secondUrl: item?.first,
    };
  });

  const handleParams = (params) => {
    console.log(params);
    console.log('https://terminology.tib.eu/ts/ontologies/dicob/terms?iri=' + encodeURIComponent(params?.row?.firstUrl));
    // return <a href={'https://terminology.tib.eu/ts/ontologies/dicob/terms?iri=' + encodeURIComponent(params?.row?.firstUrl)} />;
    // return <a href={`https://terminology.tib.eu/ts/ontologies/dicob/terms?iri=https%3A%2F%2Fw3id.org%2Fdigitalconstruction%2F0.5%2FAgents%23Agent`} />;
    window.location.href = `https://terminology.tib.eu/ts/ontologies/dicob/terms?iri=https%3A%2F%2Fw3id.org%2Fdigitalconstruction%2F0.5%2FAgents%23Agent`;
  };
  return (
    <div style={modalStyle} className={classes.paper}>
      <DataGrid
        rowClick="show"
        rows={rows}
        columns={columns}
        pageSize={100}
        autoHeight={true}
        rowsPerPageOptions={[100]}
        disableSelectionOnClick
        onRowClick={(params) => handleParams(params)}
        classes={{
          columnHeader: classes.columnHeader,
          cell: classes.cell,
        }}
        sx={{
          '& .MuiDataGrid-iconSeparator': {
            display: 'none',
          },
          '& .MuiSvgIcon-root': {
            color: 'white',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '16px',
            fontWeight: '500',
          },
        }}
      />
    </div>
  );
});
