import { QualityAssessment } from './QualityAssessment';
import { CircularProgress } from '@mui/material';
import { FetchGithubMetaData } from 'api/queryData';

const QualityAssessmentData = ({ githubOntologies }) => {
  const { isLoading, isError, error, data } = FetchGithubMetaData();

  return (
    <div>
      {isLoading ? (
        <div>
          <CircularProgress />
        </div>
      ) : isError ? (
        <div>Error: {error.message}</div>
      ) : (
        <QualityAssessment repositoryData={data} githubOntologies={githubOntologies} />
      )}
    </div>
  );
};

export default QualityAssessmentData;
