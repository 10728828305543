import Navbar from '../Navbar/Navbar';
import TIB_Logo from '../../../assets/img/TIB_Logo_W_W.SVG';
import SearchForm from '../../Search/SearchForm/SearchForm';
import { useLocation } from 'react-router-dom';
import LoginPanel from '../../User/Login/TS/LoginPanel';

function Header() {
  const location = useLocation();
  return (
    <div className="row header-wrapper header-collapseable-section">
      <div className="col-sm-10 site-header">
        <div className="row">
          <div className="col-sm-11 text-right">
            <LoginPanel isModal={true}></LoginPanel>
          </div>
        </div>
        <div className="row site-header-nav-logo-holder">
          <div className="col-lg-4 col-md-6">
            {process.env.REACT_APP_PROJECT_ID === 'general' && (
              <span>
                <img src={TIB_Logo} alt="tib logo" height={60} width={80} />
                <a className="main-title" href={process.env.REACT_APP_PROJECT_SUB_PATH + '/'}>
                  TERMINOLOGY SERVICE
                </a>
              </span>
            )}
            {process.env.REACT_APP_PROJECT_ID === 'nfdi4ing' && (
              <span>
                <a className="main-title" href={process.env.REACT_APP_PROJECT_SUB_PATH + '/'}>
                  <img src="/NFDI4ING_ALT_LOGO.png" alt="NFDI4ING_ALT_LOGO" className="site-logo-image" />
                </a>
              </span>
            )}
          </div>
          <Navbar />
        </div>
        {location.pathname.includes('/ts/sandbox') ? null : <SearchForm />}
      </div>
    </div>
  );
}

export default Header;
