import { useState } from 'react';
import { GetTotalElements, GetOntologiesByCollection } from '../../../api/queryData';
import Select from 'react-select';
import { SelectSecondOntology } from './SelectSecondOntology';
import axios from 'axios';
import { useQuery } from 'react-query';

export const SelectFirstOntology = ({ collection }) => {
  const [firstId, setFirstId] = useState('');
  const [firstTitle, setFirstTitle] = useState('');

  const { data: classification } = GetOntologiesByCollection(collection);

  const fetchSimilaritiesByCollection = (collection, firstId) => {
    return axios.get(`${process.env.REACT_APP_MAPPING_BACKEND_URL}/api/ontology/similarity/pairwise/internal?collection=${collection}&id=${firstId}`);
  };

  const { data: similarities } = useQuery(['similarity-by-collection', collection, firstId], () => fetchSimilaritiesByCollection(collection, firstId), {
    enabled: !!firstId,
    select: (data) => {
      const similarities = data?.data?.content;
      return similarities;
    },
  });

  const { data: totalElements } = GetTotalElements(firstId);
  console.log(totalElements);

  const fetchClasses = (firstId, totalElements) => {
    return axios.get(`https://service.tib.eu/ts4tib/api/ontologies/${firstId}/terms?page=0&size=${totalElements}`);
  };

  const { data: firstOntologyClasses } = useQuery(['classes-for-', firstId], () => fetchClasses(firstId, totalElements), {
    enabled: !!totalElements,
    select: (data) => {
      const classes = data?.data?._embedded?.terms.map((item) => {
        return {
          iri: item?.iri,
          label: item?.label,
        };
      });
      return classes;
    },
  });

  console.log(firstOntologyClasses);

  const handleId = (value, { action }) => {
    if (action === 'clear') {
      setFirstId('');
      setFirstTitle('');
    }
    if (action === 'select-option') {
      setFirstId(value?.id);
      setFirstTitle(value?.title);
    }
  };
  console.log(firstId);

  function customTheme(theme) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: '#e9fcfc',
        primary: '#4f6d7a',
      },
    };
  }

  return (
    <div>
      <Select
        isClearable
        options={classification}
        defaultValue={{ ontologId: 'Select an Ontology', value: 0 }}
        onChange={handleId}
        getOptionValue={(option) => option.id}
        autoFocus
        isSearchable
        placeholder={'Select an Ontology'}
        theme={customTheme}
      />
      {similarities && <SelectSecondOntology similarities={similarities} totalElements={classification?.data?.page?.totalElements} />}
    </div>
  );
};
