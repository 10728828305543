import { useState, useEffect } from 'react';
import { Column } from '@ant-design/plots';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      overflow: 'auto',
      border: 'solid rgba(224, 224, 224, 1) 1px',
      padding: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  })
);

export const PairwiseSimilarityBar = (props) => {
  const [data, setData] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    async function fetchData() {
      const data = getData(props);
      setData(data);
    }
    fetchData();
  }, []);

  function getData(props) {
    let pairwiseSimilarity = props.pairwiseSimilarity;
    let array = ['property', 'class', 'import', 'namespace', 'individual'];
    let pairs = [];

    array.forEach((name) => {
      let item = {
        pair: pairwiseSimilarity.pair.first + '-' + pairwiseSimilarity.pair.second,
        value: Math.round((pairwiseSimilarity['characteristics'][name]['percent'] + Number.EPSILON) * 100) / 100,
        type: name,
      };
      pairs.push(item);
    });

    return pairs;
  }

  const config = {
    data,
    // minColumnWidth: 50,
    maxColumnWidth: 100,
    // columnStyle: {
    //   fill: 'red',
    //   fillOpacity: 0.5,
    //   stroke: 'black',
    //   lineWidth: 1,
    //   lineDash: [4, 5],
    //   strokeOpacity: 0.7,
    //   shadowColor: 'black',
    //   shadowBlur: 10,
    //   shadowOffsetX: 5,
    //   shadowOffsetY: 5,
    //   cursor: 'pointer',
    // },
    // width: 100,
    // height: 180,
    isStack: true,
    xField: 'pair',
    yField: 'value',
    seriesField: 'type',
    // meta: {
    //     value: {
    //     min: 0,
    //     max: 100,
    //    },
    // },
    label: {
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
    },
    color: ['#FA8072', '#FFA500', '#FFFF00', '#00FF7F', '#00BFFF'],
  };

  return (
    <div className={classes.root}>
      <Column {...config} />
    </div>
  );
};
