import axios from 'axios';
import { useQuery } from 'react-query';
import { githubService } from './networkGit/github.service';
import { gitlabService } from './networkGit/gitlab.service';
import { gitTIBService } from './networkGit/gitTIB.service';
import { gitAachenService } from './networkGit/gitAachen.service';
import { gitOnem2mService } from './networkGit/gitOnem2m.service';

export const GetAllCollections = () => {
  return useQuery(
    'all-collections',
    () => {
      return axios.get('https://service.tib.eu/ts4tib/api/ontologies/schemavalues?schema=collection');
    },
    {
      select: (data) => {
        const allCollections = data?.data?._embedded?.strings;
        return allCollections;
      },
    }
  );
};

export const GetOntologiesByCollection = (collection) => {
  const fetchOntologiesByCollections = (collection) => {
    return axios.get(`https://service.tib.eu/ts4tib/api/ontologies/filterby?schema=collection&classification=${collection}&exclusive=false&size=1000`);
  };
  return useQuery(['ontologies-by-collections', collection], () => fetchOntologiesByCollections(collection), {
    select: (data) => {
      const allCollections = data?.data?._embedded?.ontologies.map((ontology) => {
        return {
          ...ontology,
          id: ontology.ontologyId,
          label: `${ontology.config.title} - ${ontology.ontologyId}`,
          title: ontology.config.title,
        };
      });
      return allCollections;
    },
  });
};

export const GetCollectionSimilarities = (collection, ontologId) => {
  const fetchSimilaritiesByCollection = (collection, ontologId) => {
    return axios.get(`http://localhost:9191/api/ontology/similarity/pairwise/internal?collection=${collection}&id=${ontologId}`);
  };

  return useQuery(['similarity-by-collection', collection, ontologId], () => fetchSimilaritiesByCollection(collection, ontologId));
};

export const GetGithubOntologies = () => {
  return useQuery(
    'github-ontologies',
    () => {
      return axios.get('https://service.tib.eu/ts4tib/api/ontologies?page=0&size=1000');
    },
    {
      select: (data) => {
        const gitHubdata = data?.data?._embedded?.ontologies
          ?.filter((ontology) => ontology.config.fileLocation.includes('https://raw.githubusercontent.com'))
          .map((ontology) => ({
            ...ontology,
            id: ontology.ontologyId,
            label: ontology.config.title,
          }));
        return gitHubdata;
      },
    }
  );
};

export const GetGithubOntologiesOwnerAndRepo = () => {
  const { isLoading, data, isError, error, isFetching } = useQuery(
    'github-ontologies',
    () => {
      return axios.get('https://service.tib.eu/ts4tib/api/ontologies?page=0&size=1000');
    },
    {
      select: (data) => {
        const ownerAndRepo = data?.data?._embedded?.ontologies
          ?.filter((ontology) => ontology.config.fileLocation.includes('https://raw.githubusercontent.com'))
          .map((ontology) => {
            const parts = ontology.config.fileLocation.split('/').splice(2);
            return {
              id: ontology.ontologyId,
              label: ontology.config.title,
              owner: parts[1],
              repo: parts[2],
            };
          });
        return ownerAndRepo;
      },
    }
  );
  return { isLoading, data, isError, error, isFetching };
};

export const FetchUris = () => {
  const githubUrl = 'https://api.github.com/repos/';
  let newUris = [];
  const { data } = GetGithubOntologiesOwnerAndRepo();
  console.log(data);
  data?.map((item) => {
    newUris = [...newUris, githubUrl + item.owner + '/' + item.repo, githubUrl + item.owner + '/' + item.repo + '/releases', githubUrl + item.owner + '/' + item.repo + '/community/profile'];
  });
  return newUris;
};

export const FetchGithubMetaData = () => {
  const newUris = FetchUris();
  console.log(newUris);
  async function FetchMetaData() {
    const repositoryData = await axios
      .all(
        newUris.map((l) =>
          axios.get(l, {
            headers: {
              Authorization: `token ${process.env.REACT_APP_GITHUB_ACCESS_TOKEN}`,
            },
          })
        )
      )
      .then(
        axios.spread(function (...res) {
          return res;
        })
      );
    return repositoryData;
  }

  return useQuery('metadata', FetchMetaData);
};

const fetchOntologyTerms = (ontologId) => {
  return axios.get(`https://service.tib.eu/ts4tib/api/ontologies/${ontologId}/terms`);
};

export const GetTotalElements = (ontologId) => {
  const { isLoading, isError, error, data, isFetching } = useQuery(['totalElements-for-', ontologId], () => fetchOntologyTerms(ontologId), {
    select: (data) => {
      const totalElements = data?.data?.page?.totalElements;
      return totalElements;
    },
  });
  return { isLoading, data, isError, error, isFetching };
};

/* These are from fetchData.ts */

const callHeader = {
  Accept: 'application/json',
};
const getCallSetting = { method: 'GET', headers: callHeader };
const size = 10000;

/**
 * get the page field from json result (TS api paginates the results)
 * @param url
 * @returns
 */
async function getPageCount(url) {
  let answer = await fetch(url, getCallSetting);
  answer = await answer.json();
  return Math.ceil(answer['page']['totalElements'] / size);
}

export const GetAllOntologies = () => {
  return useQuery('all-ontologies', () => {
    return axios.get('https://service.tib.eu/ts4tib/api/ontologies?page=0&size=1000');
  });
};

/**
 * Get the Github Commits
 * @returns A list
 */

export async function getRepositoryCommits(ontology) {
  if (ontology.config.fileLocation === '') return;
  const url = ontology.config.fileLocation;
  const parts = url.split('/').splice(2);
  const githubUrl = 'https://api.github.com/repos/' + parts[1] + '/' + parts[2] + '/commits';
  const response = await fetch(githubUrl, {
    headers: {
      Authorization: `token ${process.env.REACT_APP_TOKEN}`,
    },
  });
  const commits = await response.json();
  const commitsWithLabel = commits.map((commit) => ({
    ...commit,
    label: commit.commit.author.date,
    id: commit.sha,
  }));
  return commitsWithLabel;
}

export async function getRepositoryVersion(ontology) {
  if (ontology.config.fileLocation === '') return;
  const url = ontology.config.fileLocation;
  const parts = url.split('/').splice(2);
  const githubUrl = 'https://api.github.com/repos/' + parts[1] + '/' + parts[2] + '/tags';
  const response = await fetch(githubUrl, {
    headers: {
      Authorization: `token ${process.env.REACT_APP_TOKEN}`,
    },
  });
  const versions = await response.json();
  const versionsWithLabel = versions.map((version) => ({
    ...versions,
    label: version.name,
    id: version.commit.sha,
    sha: version.commit.sha,
  }));
  return versionsWithLabel;
}

/**
 * Get all ontologies in a collection
 * @returns A list
 */
export async function getOntologiesByClassification(classification) {
  const url = `https://service.tib.eu/ts4tib/api/ontologies/filterby?schema=collection&classification=${classification}`;
  let answer = await fetch(url, getCallSetting);
  answer = await answer.json();
  let ontologiesCount = answer['page']['totalElements'];
  let targetUrl = `${url}&exclusive=false&size=${ontologiesCount}`;
  return fetch(targetUrl, getCallSetting)
    .then((s) => s.json())
    .then((s) => {
      return s['_embedded']['ontologies'];
    })
    .catch((s) => {
      return [];
    });
}
/**
 * Get all the ids
 * @returns A list
 */
export async function getAllOntologiesIds() {
  let answer = await fetch(`${process.env.REACT_APP_MAPPING_BACKEND_URL}/api/ontology/ids`, getCallSetting);
  answer = await answer.json();
  return fetch(`${process.env.REACT_APP_MAPPING_BACKEND_URL}/api/ontology/ids`, getCallSetting)
    .then((s) => s.json())
    .then((s) => {
      return s;
    })
    .catch((s) => {
      return [];
    });
}

/**
 *
 * @param firstId
 * @param allIds
 */
export async function getMappings(collection, firstId, allIds) {
  const url = `${process.env.REACT_APP_MAPPING_BACKEND_URL}/api/ontology/similarity/pairwise/internal?collection=${collection}&id=${firstId}&${allIds
    .map((e) => 'ids=' + e)
    .toString()
    .replaceAll(',', '&')}`;
  try {
    let response = await fetch(url);
    response = await response.json();
    return response['_embedded']['similarities'];
  } catch (error) {
    console.log('error', error);
    return undefined;
  }
}

/**
 * Ontologies functions for History Management tool
 */

export const GetOntologiesHistory = () => {
  return useQuery(
    'ontologies-history',
    () => {
      return axios.get(`${process.env.REACT_APP_DIFF_BACKEND_URL}/api/history/ontologies`);
    },
    {
      select: (data) => {
        return data.data;
      },
    }
  );
};

export const getOntology = (id) => {
  return fetch(`${process.env.REACT_APP_DIFF_BACKEND_URL}/api/history/ontologies/${id}`);
};

export const getPreviewData = (id, startDate, endDate) => {
  const isoStartDate = startDate.toISOString();
  const IstEndDate = endDate.toISOString();

  return fetch(`${process.env.REACT_APP_DIFF_BACKEND_URL}/api/history/ontologies/${id}/preview?startDate=${isoStartDate}&endDate=${IstEndDate}`, {
    method: 'POST',
  });
};

export function selectService(service) {
  if (service === 'github') {
    return githubService;
  } else if (service === 'gitlab') {
    return gitlabService;
  } else if (service === 'gitTIB') {
    return gitTIBService;
  } else if (service === 'gitAachen') {
    return gitAachenService;
  } else if (service === 'gitOnem2m') {
    return gitOnem2mService;
  }
}

export function getCommitShaType(service) {
  if (service === 'github') {
    return 'sha';
  } else if (service === 'gitlab' || service === 'gitTIB' || service === 'gitAachen' || service === 'gitOnem2m') {
    return 'id';
  }
}

export function getCommitMessage(service, data) {
  if (service === 'github') {
    return data.commit.message;
  } else if (service === 'gitlab' || service === 'gitTIB' || service === 'gitAachen' || service === 'gitOnem2m') {
    return data.message;
  }
}

export function getCommitDate(service, data) {
  if (service === 'github') {
    return data.commit.author.date;
  } else if (service === 'gitlab' || service === 'gitTIB' || service === 'gitAachen' || service === 'gitOnem2m') {
    return data.committed_date;
  }
}
