import { Tabs, Tab, Typography, Grid } from '@mui/material';
import { useState } from 'react';
import { a11yProps, TabPanel } from '../theme/Theme';
import { GetAllCollections } from '../../../api/queryData';
import { SelectFirstOntology } from './SelectFirstOntology';
import { MappingTypography } from './MappingTypography';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  tab: {
    color: theme.palette.primary.light,
    fontWeight: 'bolder',
    backgroundColor: 'none',
    borderRadius: '5px 5px 0px 0px',
  },
  tabs: {
    transform: 'translate(0px, 1px)',
    '& .Mui-selected': {
      backgroundColor: 'white',
      borderRight: '1px solid',
      borderTop: '1px solid',
      borderLeft: '1px solid',
      borderRightColor: theme.palette.grey[300],
      borderLeftColor: theme.palette.grey[300],
      borderTopColor: theme.palette.grey[300],
    },
  },
  tabpanel: {
    borderTop: '1px solid',
    borderColor: theme.palette.grey[300],
  },
  grid: {
    padding: theme.spacing(2),
  },
  tableHeadBackground: {
    backgroundColor: 'red',
  },
}));

export const Mappings = () => {
  const classes = useStyles();
  const [value, setValue] = useState(8);
  const { data: collections } = GetAllCollections();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Grid container>
      <Grid item xs={12} md={12} className={classes.grid}>
        <Tabs className={classes.tabs} value={value} onChange={handleChange} TabIndicatorProps={{ style: { display: 'none' } }}>
          {collections?.map((collection, index) => (
            <Tab className={classes.tab} label={collection.content} {...a11yProps(index)} key={index} />
          ))}
        </Tabs>
        {collections?.map((collection, index) => (
          <TabPanel className={classes.tabpanel} value={value} index={index} key={index}>
            <MappingTypography />
            <Typography align="center" component={'div'}>
              Browse mappings between classes in different ontologies. Select an Ontology from <strong>{collection.content}</strong> Collection
            </Typography>
            <SelectFirstOntology collection={collection.content} />
          </TabPanel>
        ))}
      </Grid>
    </Grid>
  );
};
