import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import Modal from '@mui/material/Modal';
import { ModalTable } from './ModalTable';
import { createRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    minHeight: '70vh',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '20px 0px',
  },
  columnHeader: {
    color: 'white',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
  },
  cell: {
    fontWeight: 'bold',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },

  tabpanel: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    minHeight: '70vh',
    padding: theme.spacing(3),
    border: '1px solid',
    borderColor: theme.palette.grey[400],
    borderRadius: ' 0 0px 10px 10px',
  },
}));

export const SelectSecondOntology = ({ similarities, totalElements }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [rowParameters, setRowParameters] = useState([]);
  const [firstTitle, setFirstTitle] = useState('');
  const [secontTitle, setSecondTitle] = useState('');
  const testRef = createRef();

  const handleOpen = (params) => {
    console.log(params);
    console.log('first title' + params?.row?.classIri?.firstTitle);
    setRowParameters(params?.row?.classIri);
    setFirstTitle(params?.row?.firstTitle);
    setSecondTitle(params?.row?.secondTitle);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    // { field: 'id', headerName: 'ID', width: 100, headerAlign: 'center' },
    {
      field: 'ontology',
      headerName: 'Ontology',
      flex: 1,
      editable: true,
    },
    {
      field: 'mappings',
      headerName: 'Mappings',
      width: 250,
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const rows = similarities?.map((item, index) => {
    return {
      id: index,
      ontology: item?.titles?.second,
      mappings: item?.characteristics?.class?.size,
      classIri: item?.characteristics?.class?.list,
      firstTitle: item?.titles?.first,
      secondTitle: item?.titles?.second,
    };
  });

  return (
    <div className={classes.root}>
      <DataGrid
        rows={rows || []}
        columns={columns}
        pageSize={totalElements}
        autoHeight={true}
        rowsPerPageOptions={[totalElements]}
        disableSelectionOnClick
        onRowClick={(params) => handleOpen(params)}
        classes={{
          columnHeader: classes.columnHeader,
          cell: classes.cell,
        }}
        sx={{
          '& .MuiDataGrid-iconSeparator': {
            display: 'none',
          },
          '& .MuiSvgIcon-root': {
            color: 'white',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '16px',
            fontWeight: '500',
          },
        }}
      />
      <Modal open={open} onClose={handleClose}>
        {rowParameters && <ModalTable ref={testRef} rowParameters={rowParameters} firstTitle={firstTitle} secondTitle={secontTitle} />}
      </Modal>
    </div>
  );
};
